import $ from 'jquery';
import jQuery from 'jquery';

// print form
$('.printbutton').on('click', function()
	{
	var form = $(this).closest('form');
	form.find('input[type=text]').each(function()
		{
		$(this).attr('value', $(this).val());
		});

		form.find('input[type=checkbox]').each(function() 
		{
		$(this).attr('checked', $(this).prop("checked"));
		});		

		form.find('input[type=radio]').each(function() 
		{
		$(this).attr('checked', $(this).prop("checked"));
		});		

		form.find('textarea').each(function() 
		{
		$(this).text($(this).val());
		});

		form.find('select').each(function() 
		{
		$(this).find('option:selected').attr('selected', 'selected');
		$(this).find('option:not(:selected)').removeAttr('selected');
		});
		var printContents = '<!doctype html><html class="no-js" lang="en">\n' +
		                    '<head>\n' +
		                    '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">\n' +
		                    '<meta charset="utf-8">\n' +
		                    '<meta http-equiv="x-ua-compatible" content="ie=edge">\n' +
		                    '<meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
		                    '<title>Print Form</title>\n' +
		                    '<link rel="stylesheet" href="/assets/css/app.css">\n' +
		                    '<style>body{ margin: 40px 0 40px 0; }</style>\n' +
		                    '</head>\n' +
		                    '<body>\n' +
		                    form.html() +
		                    '</body>\n' +
		                    '</html>\n';
		var w=window.open();
		w.document.write(printContents);
//		w.print();
//		w.close();
		return false;
	});

// testing buttons 
/*
$(function()
	{
		var headContact = "<a title=\"Phone us\" href=\"tel:0264542346\"><i class=\"fi-telephone\"></i></a><a title=\"Email us\" href=\"mailto:bill@suttonnet.com.au\"><i class=\"fi-mail\"></i></a>";
		var headMail = "<a title=\"Email us\" href=\"mailto:bill@suttonnet.com.au\"><i class=\"fi-mail\"></i></a>";
		var headPhone = "<a title=\"Phone us\" href=\"tel:0264542346\"><i class=\"fi-telephone\"></i></a>";
		
  $('.btn1').click(function(){
   	$('.accordion-title').css({'color': 'red', 'content': '+'}); 
 //  	$('.accordion-title').css({'border-color': 'red', 'content': '+'}); 
  });
  
   
});
*/

/*
<a title="Phone us" href="tel:0264542346">
	<i class="fi-telephone"></i>
</a>
<a title="Email us" href="mailto:bill@suttonnet.com.au">
	<i class="fi-mail"></i>
</a>
*/

		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		